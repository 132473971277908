// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAkD2fPhekV8a4YNLTxZJTiLlsvLqU_aGA",
    authDomain: "pulseapp-ce996.firebaseapp.com",
    databaseURL: "https://pulseapp-ce996.firebaseio.com",
    projectId: "pulseapp-ce996",
    storageBucket: "pulseapp-ce996.appspot.com",
    messagingSenderId: "767199684147",
    appId: "1:767199684147:web:3706d50a87db705754a546"
  },
  api_url: "http://localhost:3000",
  withDoorbell: false,
  buildSha: "local"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
