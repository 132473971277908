<!--<body>-->
<!--<div fxFlexFill fxLayout="column">-->
<!--<app-shell fxFlexFill>-->
<!--  <router-outlet fxFlexFill></router-outlet>-->
<!--</app-shell>-->
<!--</div>-->
<!--</body>-->
<body class="mat-typography">
<div fxFlexFill fxLayout="column">
    <app-shell>
      <router-outlet></router-outlet>
    </app-shell>
</div>

<script type="text/javascript">

</script>
</body>
