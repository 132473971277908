import { Injectable } from '@angular/core';
import {Observable, of} from "rxjs";
import {FirebaseUser} from "./user.model";
import {AngularFireAuth} from "@angular/fire/auth";
import {AngularFirestore, AngularFirestoreDocument} from "@angular/fire/firestore";
import {Router} from "@angular/router";
import {switchMap, take} from "rxjs/operators";
import {auth} from "firebase";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$: Observable<FirebaseUser>;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router
  ) {
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          console.log("LOGGED IN");
          return this.afs.collection<FirebaseUser>( "users", ref => ref.where( 'userIDs',
                'array-contains',
              user.uid
            ).limit(1)
          ).valueChanges();
          // User is logged in
          // TODO: remove hardcoded JWT
          // return this.afs.doc<User>(`users/123`).valueChanges();
          // return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          console.log("NOT LOGGED IN");
          // User is not logged in
          return of(null);
        }
      })
    );
  }

  // async googleSignin() {
  //   const provider = new auth.GoogleAuthProvider();
  //   provider.setCustomParameters({
  //     hd: "pivotpl.com"
  //   });
  //   const credential = await this.afAuth.signInWithPopup(provider);
  //   // Update user data
  //   return this.updateUserData(credential.user);
  // }

  public anonSignin(jwtID: string) {
    //getUserFromDB
    const credentials = this.afAuth.signInAnonymously();
    // TODO: add credentials.user.uid to user.userIDs for tracking purposes
    this.user$ = this.afs.doc<FirebaseUser>(`users/${jwtID}`).valueChanges().pipe(
      take(1)
    );
    return this.user$;
    //return this.getUserData(jwtID);

    // userRef.subscribe(e => {
    //   if (e.exists) {
    //     console.log(creds.user);
    //     console.log("Document data:", e.data());
    //   } else {
    //     // doc.data() will be undefined in this case
    //     console.log("No such document!");
    //   }
    // });
  }

  async signout() {
    await this.afAuth.signOut();
    return this.router.navigate(['/']);
  }

  private getUserData(uid: string) {
    const userRef: AngularFirestoreDocument<FirebaseUser> = this.afs.collection("users").doc(uid);
    return userRef.get()
  }

  private updateUserData(user: FirebaseUser) {
    const userRef: AngularFirestoreDocument<FirebaseUser>  = this.afs.doc(`users/${user.uid}`);
    const data = {
      able: user.able,
      uid: user.uid,
      school: user.school,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      userIDs: user.userIDs,
    };

    return userRef.set(data, {merge: true});
  }

  private updateUserIDs(uid: string, u: FirebaseUser, userid: string) {
    const userRef: AngularFirestoreDocument<FirebaseUser>  = this.afs.doc(`users/${uid}`);

    var sessionList: string[] = u.userIDs;
    sessionList.push(u.uid);

    const data = {
      able: u.able,
      uid: uid,
      school: u.school,
      email: u.email,
      displayName: u.displayName,
      photoURL: u.photoURL,
      userIDs: sessionList,
    };

    return userRef.set(data, {merge: true});
  }

}
