<div class="container roundcorners">
  <mat-sidenav-container class="sidevnav-container roundcorners" fxFlexFill>
    <mat-sidenav #drawer class="sidenav roundcorners" fixedInViewport
                 [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
                 [mode]="(isHandset$ | async) ? 'over' : 'side'"
                 [opened]="false"
    >
      <mat-toolbar>Menu</mat-toolbar>
      <!--      <mat-nav-list class="roundcorners">-->
      <!--        <a mat-list-item routerLink="/" (click)="drawer.close()">About</a>-->
      <!--        <a mat-list-item routerLink="/" (click)="drawer.close()">Why Pivot?</a>-->
      <!--        <a mat-list-item routerLink="/" (click)="drawer.close()">Our Products</a>-->
      <!--        <a mat-list-item routerLink="/" (click)="drawer.close()">Institutional Clients</a>-->
      <!--        <a mat-list-item routerLink="/" (click)="drawer.close()">Pivot Blog</a>-->
      <!--      </mat-nav-list>-->
    </mat-sidenav>
    <mat-sidenav-content class="roundcorners">
      <mat-toolbar style="border-radius: 5px 5px 0 0" color="primary">
        <button
          type="button"
          mat-icon-button
          (click)="drawer.toggle()"
          *ngIf="(isHandset$ | async) || (isTablet$ | async)">
          <mat-icon>menu</mat-icon>
        </button>
        <span class="logo" routerLink="/">Pivot Professional Learning</span>
        <span class="fill-space"></span>
        <!--        <div *ngIf="!(isHandset$ | async) && !(isTablet$ | async)">-->
        <!--          <a mat-button routerLink="/">About</a>-->
        <!--          <a mat-button routerLink="/">Why Pivot?</a>-->
        <!--          <a mat-button routerLink="/">Our Products</a>-->
        <!--          <a mat-button routerLink="/">Institutional Clients</a>-->
        <!--          <a mat-button routerLink="/">Pivot Blog</a>-->
        <!--        </div>-->
        <button mat-icon-button *ngIf="!(afAuth.authState | async)" [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <button mat-icon-button *ngIf="afAuth.authState | async as user" [matMenuTriggerFor]="menu">
          <mat-icon *ngIf="!(!!user.photoURL)">more_vert</mat-icon>
          <img *ngIf="user.photoURL" class="avatar" src="{{user.photoURL}}">
        </button>
        <mat-menu #menu="matMenu">
          <!--          <a mat-menu-item *ngIf="!(afAuth.authState | async)" routerLink="/login">Login</a>-->
          <!--          <a mat-menu-item *ngIf="(afAuth.authState | async)" routerLink="/onboarding">Onboard</a>-->
          <!--          <a mat-menu-item *ngIf="(afAuth.authState | async)" routerLink="/user/profile">Profile</a>-->
          <!--          <a mat-menu-item *ngIf="(afAuth.authState | async)" (click)="afAuth.signOut()" routerLink="/">Logout</a>-->
          <a mat-menu-item routerLink="/">Contact Us</a>
        </mat-menu>
      </mat-toolbar>
      <div id="mainarea">
        <ng-content></ng-content>
      </div>
      <div class="roundcorners" id="footer">
        <app-footer></app-footer>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

