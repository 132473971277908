<div fxFlex="column">
  <div class="pt-2 pb-2" id="bottommsg" fxLayoutAlign="center center">
    <app-bottom-message></app-bottom-message>
  </div>
  <div class="pt-3">
    <div fxFlex="25">
      <img width="25%" src="/assets/images/svg/PivotWhite.svg"><br>
        <span>© 2020 Copyright:
          <a href="https://pivotpl.com/">PivotPL.com</a></span>
    </div>
    <div fxFlex="25" fxLayoutAlign="center center">
      <p>Email: info@pivotpl.com<br>
         Phone: +61 481 874 686</p>
    </div>
    <div fxFlex="25" fxLayoutAlign="center center">
      <p>Privacy Policy<br>
         Terms of Use</p>
    </div>
    <div fxFlex="25" fxLayoutAlign="center center">
      <p>Download Free Trial Guide</p>
    </div>
  </div>
</div>

