import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from "./auth.service";
import {map, take, tap} from "rxjs/operators";
import {SnackService} from "./snack.service";
import {AngularFireAuth} from "@angular/fire/auth";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private auth: AuthService, private router: Router, private snack: SnackService, private afAuth: AngularFireAuth) {}

  // async canActivate(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot,
  // ): Promise<boolean>{
  //   const user = await this.auth.user$;
  //   const isLoggedIn = !!user;
  //   console.log("Logged in: " + isLoggedIn);
  //   console.log(user);
  //   if (!isLoggedIn) {
  //     this.snack.validateError("Invalid user");
  //   }
  //   return isLoggedIn;
  // }

  canActivate(next, state) {
    return this.afAuth.authState.pipe(
      map((a) => {
        if (!a) {
          this.router.navigate(['login']);
          return false;
        }
        return true;
      },
      take(1)
  )
    )
    // return this.auth.user$.pipe(
    //   take(1),
    //   map( user => !!user),
    //   tap(loggedIn => {
    //     if (!loggedIn) {
    //       this.router.navigate(['/login']);
    //     }
    //   })
    // );
  }
}
