import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomePageComponent} from "./home-page/home-page.component";
import {AuthGuard} from "./services/auth.guard";
import {OnboardingModule} from "./onboarding/onboarding.module";


const routes: Routes = [
  // {path: '', component: HomePageComponent},
  {path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule)},
  {path: 'validate', loadChildren: () => import('./login/login.module').then(m => m.LoginModule)},
  {path: 'user', loadChildren: () => import('./user/user.module').then(m => m.UserModule), canActivate: [AuthGuard]},
  {path: '', loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingModule)}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
