import {MatIconRegistry} from "@angular/material/icon";
import {Injectable} from "@angular/core";
import {DomSanitizer} from "@angular/platform-browser";

export enum Icons {
  PivotWhite = 'PivotWhite',
}

@Injectable({
  providedIn: 'root'
})

export class IconService {

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ){}


  public registerIcons(): void {
    this.loadIcons(Object.values(Icons), '../../assets/images/svg);');
  }

  private loadIcons(iconKeys: string[], iconUrl: string): void {
    iconKeys.forEach(key => {
      this.matIconRegistry.addSvgIcon(key, this.domSanitizer.bypassSecurityTrustResourceUrl(`${iconUrl}/${key}.svg`));
    });
  }
}

